import { handleActions } from 'redux-actions';
import { requestSuccess, requestFail } from '../../api/request';
import * as CONSTANTS from './constants';

const getInitialState = () => {
  return {
    status: 'INIT',
    synonym_list: {},
    info: {},
    pathWord: {},
    wholeWord: {},
    hint_status: null,
    hints: [],
    error: null,
  };
};

export default handleActions(
  {
    [CONSTANTS.GET_WORD_PROPERTIES]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_WORD_PROPERTIES)]: (state, { payload }) => {
      return {
        ...state,
        synonym_list: payload.word,
      };
    },
    [requestFail(CONSTANTS.GET_WORD_PROPERTIES)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_WORD_INFO]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_WORD_INFO)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      info: {
        ...state.info,
        [payload.word]: payload,
      },
    }),
    [requestFail(CONSTANTS.GET_WORD_INFO)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_PATH_OPTIMAL]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_PATH_OPTIMAL)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      pathWord: payload,
    }),
    [requestFail(CONSTANTS.GET_PATH_OPTIMAL)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_WORD_OPTIMAL]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_WORD_OPTIMAL)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      wholeWord: payload,
    }),
    [requestFail(CONSTANTS.GET_WORD_OPTIMAL)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_WORD_HINT_STATUS]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_WORD_HINT_STATUS)]: (state, { payload }) => ({
      ...state,
      hint_status: payload?.status,
      hints: [...state.hints, payload?.status],
    }),
    [requestFail(CONSTANTS.GET_WORD_HINT_STATUS)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.UPDATE_STATUS]: (state) => ({
      ...state,
      status: 'SUCCESS',
    })
  },

  getInitialState()
);
