import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { requestFail, requestPending, requestSuccess } from './request';
import { BASE_API_URL } from '../../utils/constants/url';

const defaultHeaders = () => {
  axios.defaults.baseURL = BASE_API_URL;
  let headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('wordalike_token')) {
    headers['Authorization'] = localStorage.getItem('wordalike_token');
  }

  return headers;
};

export default ({
  type,
  method,
  path,
  headers,
  success,
  fail,
  payloadOnSuccess,
  payloadOnFail,
}) =>
  function* (action) {
    const {
      body,
      params,
      success: successCallback,
      fail: failCallback,
    } = action.payload || {};

    try {
      yield put({
        type: requestPending(type),
      });

      const res = yield call(axios.request, {
        url: typeof path === 'function' ? path(action) : path,
        method: method.toLowerCase(),
        headers: Object.assign({}, defaultHeaders(), headers),
        data: body,
        params,
      });

      const authToken = res.headers.get('Authorization');
      if (authToken) {
        localStorage.setItem('wordalike_token', authToken);
      }
      success && success(res, action);
      successCallback && successCallback(res);

      yield put({
        type: requestSuccess(type),
        payload: payloadOnSuccess
          ? payloadOnSuccess(res.data, action)
          : res.data,
      });
    } catch (err) {
      const errRes = get(err, 'response', err);

      if (
        errRes.data &&
        errRes.data.code === 401 &&
        errRes.data.message === 'Please authenticate'
      ) {
        yield put({
          type: 'IS_UNAUTHORIZED',
        });
      }

      fail && fail(errRes);
      failCallback && failCallback(errRes);

      yield put({
        type: requestFail(type),
        payload: payloadOnFail ? payloadOnFail(errRes, action) : errRes,
      });
    }
  };
