import { debounce, takeEvery } from 'redux-saga/effects';
import * as CONSTANTS from '../modules/word/constants';
import apiCall from '../api/apiCall';

const getWordProperties = apiCall({
  type: CONSTANTS.GET_WORD_PROPERTIES,
  method: 'get',
  path: ({ payload }) => `word/word/${payload.word}`,
});

const getWordInfo = apiCall({
  type: CONSTANTS.GET_WORD_INFO,
  method: 'get',
  path: ({ payload }) => `word/info/${payload.word}`,
  payloadOnSuccess: (payload, action) => ({
    ...payload,
    word: action.payload.word,
  }),
});

const getWordOptimal = apiCall({
  type: CONSTANTS.GET_WORD_OPTIMAL,
  method: 'get',
  path: ({ payload }) => `word/optimal/${payload.word}`,
});

const getPathOptimal = apiCall({
  type: CONSTANTS.GET_PATH_OPTIMAL,
  method: 'get',
  path: ({ payload }) =>
    `word/pathoptimal?start=${payload.start.word}&target=${payload.target.word}`,
});

const getWordHintStatus = apiCall({
  type: CONSTANTS.GET_WORD_HINT_STATUS,
  method: 'get',
  path: 'word/hint',
  payloadOnSuccess: (payload, action) => ({
    ...payload,
    word: action.payload.params.current,
  }),
});

export default function* rootSaga() {
  yield debounce(20, CONSTANTS.GET_WORD_PROPERTIES, getWordProperties);
  yield takeEvery(CONSTANTS.GET_WORD_INFO, getWordInfo);
  yield debounce(20, CONSTANTS.GET_WORD_OPTIMAL, getWordOptimal);
  yield debounce(20, CONSTANTS.GET_PATH_OPTIMAL, getPathOptimal);
  yield takeEvery(CONSTANTS.GET_WORD_HINT_STATUS, getWordHintStatus);
}
