import { createContext, useEffect, useState } from 'react';

const getInitialSetting = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('hint-indicator');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }
  }

  return 'on';
};

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(getInitialSetting);

  useEffect(() => {
    localStorage.setItem('hint-indicator', settings);
  }, [])

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
