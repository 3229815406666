import { debounce, takeLatest } from 'redux-saga/effects';
import * as CONSTANTS from '../modules/puzzle/constants';
import apiCall from '../api/apiCall';

const getPuzzleData = apiCall({
  type: CONSTANTS.GET_PUZZLE_DATA,
  method: 'get',
  path: ({ payload }) => `word/puzzles?date=${payload.date}`,
});

const getStartPuzzle = apiCall({
  type: CONSTANTS.GET_START_PUZZLE,
  method: 'get',
  path: ({ payload }) =>
    `puzzle/start/${payload.puzzle_id}`,
});

const putYesterdayPuzzleData = apiCall({
  type: CONSTANTS.PUT_YESTERDAY_PUZZLE_DATA,
  method: 'put',
  path: ({ payload }) =>
    `puzzle/storeYesterday/${payload.puzzle_id}`,
})

const putCompletePuzzle = apiCall({
  type: CONSTANTS.PUT_COMPLETE_PUZZLE,
  method: 'put',
  path: ({ payload }) => `puzzle/complete/${payload.puzzle_id}`,
});

const getPuzzleResult = apiCall({
  type: CONSTANTS.GET_PUZZLE_RESULT,
  method: 'get',
  path: ({ payload }) => `puzzle/result/${payload.puzzle_id}`,
});

const putPuzzleStory = apiCall({
  type: CONSTANTS.PUT_PUZZLE_STORY,
  method: 'put',
  path: 'puzzle/story',
});

const getUserStats = apiCall({
  type: CONSTANTS.GET_USER_STATS,
  method: 'get',
  path: 'puzzle/stats',
});

export default function* rootSaga() {
  yield debounce(50, CONSTANTS.GET_PUZZLE_DATA, getPuzzleData);
  yield debounce(20, CONSTANTS.GET_START_PUZZLE, getStartPuzzle);
  yield takeLatest(CONSTANTS.PUT_YESTERDAY_PUZZLE_DATA, putYesterdayPuzzleData);
  yield takeLatest(CONSTANTS.PUT_COMPLETE_PUZZLE, putCompletePuzzle);
  yield debounce(20, CONSTANTS.GET_PUZZLE_RESULT, getPuzzleResult);
  yield takeLatest(CONSTANTS.PUT_PUZZLE_STORY, putPuzzleStory);
  yield takeLatest(CONSTANTS.GET_USER_STATS, getUserStats);
}
