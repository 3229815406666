import { handleActions } from 'redux-actions';
import { requestSuccess, requestFail } from '../../api/request';
import * as CONSTANTS from './constants';
import { COMPLETE_STATUS } from 'utils/constants/list';

const getInitialState = () => {
  return {
    direction: 'right',
    data: {},
    twoDaysData: {},
    status: '',
    user_path: [],
    result: {},
    stats: {},
    error: null,
    story: null,
    fastestPath: [],
    fastestPathLength: 0,
    isLoadingStory: false,
    isLoadingResult: false,
    gameOption: 'today',
    puzzleStatus: COMPLETE_STATUS.NOT_STARTED,
    showStats: false,
    completeToday: false,
  };
};

export default handleActions(
  {
    [CONSTANTS.GET_PUZZLE_DATA]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_PUZZLE_DATA)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      data: {
        puzzle_number: payload?.puzzle_number,
        id: payload?.puzzle_id,
        start_word: payload?.start_word,
        target_word: payload?.target_word,
      },
      twoDaysData: {
        today: {
          id: payload?.puzzle_id,
          start_word: payload?.start_word,
          target_word: payload?.target_word,
        },
        yesterday: {
          id: payload?.yesterday?.puzzle_id,
          start_word: payload?.yesterday?.start_word,
          target_word: payload?.yesterday?.target_word,
        }
      },
      gameOption: 'today',
    }),
    [requestFail(CONSTANTS.GET_PUZZLE_DATA)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_START_PUZZLE]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_START_PUZZLE)]: (state) => ({
      ...state,
      status: 'SUCCESS',
      puzzleStatus: COMPLETE_STATUS.INCOMPLETE,
    }),
    [requestFail(CONSTANTS.GET_START_PUZZLE)]: (state) => ({
      ...state,
      status: 'FAILED',
    }),

    [CONSTANTS.PUT_COMPLETE_PUZZLE]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.PUT_COMPLETE_PUZZLE)]: (state) => ({
      ...state,
      status: 'SUCCESS',
      completeToday: true,
    }),
    [requestFail(CONSTANTS.PUT_COMPLETE_PUZZLE)]: (state) => ({
      ...state,
      status: 'FAILED',
    }),

    [CONSTANTS.GET_PUZZLE_RESULT]: (state) => ({
      ...state,
      isLoadingResult: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_PUZZLE_RESULT)]: (state, { payload }) => ({
      ...state,
      isLoadingResult: 'SUCCESS',
      result: payload,
    }),
    [requestFail(CONSTANTS.GET_PUZZLE_RESULT)]: (state, { payload }) => ({
      ...state,
      isLoadingResult: 'FAILED',
      result: payload,
    }),

    [CONSTANTS.GET_USER_STATS]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_USER_STATS)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      stats: payload.result,
    }),
    [requestFail(CONSTANTS.GET_USER_STATS)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.PUT_PUZZLE_STORY]: (state) => ({
      ...state,
      isLoadingStory: true,
    }),
    [requestSuccess(CONSTANTS.PUT_PUZZLE_STORY)]: (state, { payload }) => ({
      ...state,
      isLoadingStory: false,
      story: payload.result,
    }),
    [requestFail(CONSTANTS.PUT_PUZZLE_STORY)]: (state, { payload }) => ({
      ...state,
      isLoadingStory: false,
      error: payload,
    }),

    [CONSTANTS.UPDATE_PUZZLE_DIRECTION]: (state) => ({
      ...state,
      direction: state.direction === 'right' ? 'reversed' : 'right',
    }),

    [CONSTANTS.UPDATE_PUZZLE_DATE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      data: {
        puzzle_number: state.twoDaysData?.puzzle_number,
        id: state.twoDaysData?.[payload.option]?.id,
        start_word: state.twoDaysData?.[payload.option]?.start_word,
        target_word: state.twoDaysData?.[payload.option]?.target_word,
      },
      gameOption: payload.option,
    }),

    [CONSTANTS.UPDATE_PUZZLE_STATUS]: (state, { payload }) => ({
      ...state,
      puzzleStatus: payload.status,
    }),

    [CONSTANTS.UPDATE_PUZZLE_STATS_SHOW]: (state, { payload }) => ({
      ...state,
      showStats: payload.stats,
    }),

    [CONSTANTS.UPDATE_PUZZLE_COMPLETED]: (state, { payload }) => ({
      ...state,
      completeToday: payload.completed,
    }),

    [CONSTANTS.STORE_USER_PATH]: (state, { payload }) => ({
      ...state,
      user_path: payload?.user_path,
    }),

    [CONSTANTS.UPDATE_FASTEST_PATH_LENGTH]: (state, { payload }) => ({
      ...state,
      fastestPathLength: payload.length,
    }),

  },

  getInitialState()
);
