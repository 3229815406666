import * as React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

const Admin = React.lazy(() => import('./pages/Admin'));
const MainComponent = React.lazy(() => import('pages/MainComponent'));

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/play' replace />} />
      <Route path='/play' element={<MainComponent />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
};

const NoMatch = () => {
  return <p>Nothing here: 404!</p>;
};

export default App;
