import keepGoingIcon from 'assets/keep_going.svg';
import colderIcon from 'assets/colder.svg';
import hotShotIcon from 'assets/hot_shot.svg';
import warmerIcon from 'assets/warmer.svg';

const YOUR_PATH_LIST = [
  'jump',
  'shock',
  'awe',
  'wonderment',
  'wonder',
  'speculate',
  'study',
  'view',
  'picture',
];

const MID_LIST = [
  {
    word: 'shock',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: warmerIcon,
    optimalCount: 2,
    optimalRate: 13,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'awe',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: keepGoingIcon,
    optimalCount: 3,
    optimalRate: 7,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'wonderment',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: colderIcon,
    optimalCount: 2,
    optimalRate: 8,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'wonder',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: warmerIcon,
    optimalCount: 1,
    optimalRate: 11,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'speculate',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: colderIcon,
    optimalCount: 2,
    optimalRate: 7,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'study',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: warmerIcon,
    optimalCount: 3,
    optimalRate: 2,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'view',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: hotShotIcon,
    optimalCount: 1,
    optimalRate: 7,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
];

const FAST_LIST = [
  {
    word: 'shock',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: warmerIcon,
    optimalCount: 2,
    optimalRate: 13,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'wonderment',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: colderIcon,
    optimalCount: 2,
    optimalRate: 8,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'speculate',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: colderIcon,
    optimalCount: 2,
    optimalRate: 7,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'study',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: warmerIcon,
    optimalCount: 3,
    optimalRate: 2,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
  {
    word: 'view',
    pronounciation: '/ wuhn-der-muhnt /',
    icon: hotShotIcon,
    optimalCount: 1,
    optimalRate: 7,
    stepsList: [
      {
        stepName: 'impress',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'dazzle',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
      {
        stepName: 'intimidate',
        partsList: [
          {
            type: 'v',
            content: 'imprint',
          },
          {
            type: 'n',
            content: 'design',
          },
        ],
      },
    ],
  },
];

const WORD_INFORMATION_LIST = [
  {
    type: 'noun',
    overview: 'rhythm',
    clsx: 'bg-gradient-tag text-white',
    tags: [
      'beat',
      'cadence',
      'cadency',
      'picture',
      'fluctuation',
      'lilt',
      'measure',
      'meter',
      'motion',
      'oscillation',
      'swaying',
      'tempo',
      'rhythm',
      'stroke',
      'undulation',
      'vibration',
    ],
  },
  {
    type: 'verb',
    overview: 'move back and forth; be suspended',
    clsx: 'bg-gradient-tag-secondary text-game-word-tag',
    tags: [
      'curve',
      'dangle',
      'flop',
      'fluctuate',
      'hang',
      'lurch',
      'pitch',
      'pivot',
      'rotate',
      'sway',
      'swivel',
      'turn',
      'twirl',
      'veer',
      'wave',
      'whirl',
      'wiggle',
      'wobble',
    ],
  },
];

const CHOICE_INFO = {
  start: {
    word: 'forgive',
    steps: 4.1,
    paths: [
      {
        name: 'Fast Path',
        value: 3,
      },
      {
        name: '1',
        value: 4,
      },
      {
        name: '2',
        value: 6,
      },
      {
        name: '3-5',
        value: 9,
      },
      {
        name: '6-9',
        value: 6,
      },
      {
        name: '10+',
        value: 3.5,
      },
    ],
    resultPercentage: 12.3,
    difficultyPercentage: 60,
  },
  end: {
    word: 'press',
    steps: 4.8,
    paths: [
      {
        name: 'Fast Path',
        value: 2.5,
      },
      {
        name: '1',
        value: 3.2,
      },
      {
        name: '2',
        value: 5.5,
      },
      {
        name: '3-5',
        value: 9,
      },
      {
        name: '6-9',
        value: 7.5,
      },
      {
        name: '10+',
        value: 4.8,
      },
    ],
    resultPercentage: 14.6,
    difficultyPercentage: 69.5,
  },
};

/* Admin */

const SINGLES_TALBE_DATA = [
  {
    word: 'extraordinary',
    category: 'noun',
    wordRank: 3508,
    synTotal: 190,
    meaning: 13,
    synFirst: 20,
    synFirstSuffix: 'verb',
    synSecond: 20,
    synSecoundSuffix: 'verb',
    synThird: 20,
    synThirdSuffix: 'verb',
    synFourth: 20,
    synFourthSuffix: 'verb',
    synLast: 20,
    synLastSuffix: 'verb',
    openedOrModified: 'modified',
  },
  {
    word: 'unprecedented',
    category: 'noun',
    wordRank: 5868,
    synTotal: 190,
    meaning: 13,
    synFirst: 20,
    synFirstSuffix: 'verb',
    synSecond: 20,
    synSecoundSuffix: 'verb',
    synThird: 20,
    synThirdSuffix: 'verb',
    synFourth: 20,
    synFourthSuffix: 'verb',
    synLast: 20,
    synLastSuffix: 'verb',
    openedOrModified: 'modified',
  },
  {
    word: 'transcendent',
    category: 'noun',
    wordRank: 18599,
    synTotal: 190,
    meaning: 13,
    synFirst: 20,
    synFirstSuffix: 'verb',
    synSecond: 20,
    synSecoundSuffix: 'verb',
    synThird: 20,
    synThirdSuffix: 'verb',
    synFourth: 20,
    synFourthSuffix: 'verb',
    synLast: 20,
    synLastSuffix: 'verb',
    openedOrModified: 'opened',
  },
  {
    word: 'otherwordly',
    category: 'noun',
    wordRank: 13921,
    synTotal: 190,
    meaning: 13,
    synFirst: 20,
    synFirstSuffix: 'verb',
    synSecond: 20,
    synSecoundSuffix: 'verb',
    synThird: 20,
    synThirdSuffix: 'verb',
    synFourth: 20,
    synFourthSuffix: 'verb',
    synLast: 20,
    synLastSuffix: 'verb',
    openedOrModified: 'modified',
  },
  {
    word: 'impeccable',
    category: 'noun',
    wordRank: 19046,
    synTotal: 190,
    meaning: 13,
    synFirst: 20,
    synFirstSuffix: 'verb',
    synSecond: 20,
    synSecoundSuffix: 'verb',
    synThird: 20,
    synThirdSuffix: 'verb',
    synFourth: 20,
    synFourthSuffix: 'verb',
    synLast: 20,
    synLastSuffix: 'verb',
    openedOrModified: '',
  },
];

const LINEUP_TALBE_DATA = [
  {
    date: 'December 25, 2022',
    startWord: 'Table cell',
    targetWord: 'Table cell',
    type: 'Random',
    estimatedDifficulty: 'Easy',
    fastPath: 4,
    preNotes: 'Table cell',
    totalFastPaths: 13,
    averageSteps: 'Easy',
    delta: 'Easy',
    completionRate: 'Easy',
    fastPathRate: 'Table cell',
    parRate: 'Table cell',
    swaptToTarget: 'Table cell',
    swapToStart: 'Table cell',
    resetRate: 'Table cell',
    backRate: 'Table cell',
    averageBacks: 'Table cell',
    timeSpent: 'Table cell',
    compareClicked: 'Easy',
    timeInCompare: 'Easy',
    shareRate: 'Easy',
    assessedDifficulty: 'Table cell',
    dayOfTheWeek: 'Sunday',
    postNotes: 'Table cell',
    lastModified: 'Table cell',
  },
  {
    date: 'December 26, 2022',
    startWord: 'Table cell',
    targetWord: 'Table cell',
    type: 'Oxymoron',
    estimatedDifficulty: 'Easy',
    fastPath: 5,
    preNotes: 'Table cell',
    totalFastPaths: 9,
    averageSteps: 'Easy',
    delta: 'Easy',
    completionRate: 'Easy',
    fastPathRate: 'Table cell',
    parRate: 'Table cell',
    swaptToTarget: 'Table cell',
    swapToStart: 'Table cell',
    resetRate: 'Table cell',
    backRate: 'Table cell',
    averageBacks: 'Table cell',
    timeSpent: 'Table cell',
    compareClicked: 'Easy',
    timeInCompare: 'Easy',
    shareRate: 'Easy',
    assessedDifficulty: 'Table cell',
    dayOfTheWeek: 'Sunday',
    postNotes: 'Table cell',
    lastModified: 'Table cell',
  },
  {
    date: 'December 27, 2022',
    startWord: 'Table cell',
    targetWord: 'Table cell',
    type: 'Random',
    estimatedDifficulty: 'Easy',
    fastPath: 7,
    preNotes: 'Table cell',
    totalFastPaths: 4,
    averageSteps: 'Easy',
    delta: 'Easy',
    completionRate: 'Easy',
    fastPathRate: 'Table cell',
    parRate: 'Table cell',
    swaptToTarget: 'Table cell',
    swapToStart: 'Table cell',
    resetRate: 'Table cell',
    backRate: 'Table cell',
    averageBacks: 'Table cell',
    timeSpent: 'Table cell',
    compareClicked: 'Easy',
    timeInCompare: 'Easy',
    shareRate: 'Easy',
    assessedDifficulty: 'Table cell',
    dayOfTheWeek: 'Sunday',
    postNotes: 'Table cell',
    lastModified: 'Table cell',
  },
  {
    date: 'December 28, 2022',
    startWord: 'transfer',
    targetWord: 'build',
    type: 'Random',
    estimatedDifficulty: 'Easy',
    fastPath: 4,
    preNotes: 'Table cell',
    totalFastPaths: 22,
    averageSteps: 'Easy',
    delta: 'Easy',
    completionRate: 'Easy',
    fastPathRate: 'Table cell',
    parRate: 'Table cell',
    swaptToTarget: 'Table cell',
    swapToStart: 'Table cell',
    resetRate: 'Table cell',
    backRate: 'Table cell',
    averageBacks: 'Table cell',
    timeSpent: 'Table cell',
    compareClicked: 'Easy',
    timeInCompare: 'Easy',
    shareRate: 'Easy',
    assessedDifficulty: 'Table cell',
    dayOfTheWeek: 'Sunday',
    postNotes: 'Table cell',
    lastModified: 'Table cell',
  },
  {
    date: 'December 29, 2022',
    startWord: 'Table cell',
    targetWord: 'Table cell',
    type: 'Random',
    estimatedDifficulty: 'Easy',
    fastPath: 4,
    preNotes: 'Table cell',
    totalFastPaths: 5,
    averageSteps: 'Easy',
    delta: 'Easy',
    completionRate: 'Easy',
    fastPathRate: 'Table cell',
    parRate: 'Table cell',
    swaptToTarget: 'Table cell',
    swapToStart: 'Table cell',
    resetRate: 'Table cell',
    backRate: 'Table cell',
    averageBacks: 'Table cell',
    timeSpent: 'Table cell',
    compareClicked: 'Easy',
    timeInCompare: 'Easy',
    shareRate: 'Easy',
    assessedDifficulty: 'Table cell',
    dayOfTheWeek: 'Sunday',
    postNotes: 'Table cell',
    lastModified: 'Table cell',
  },
];

const PAIRS_DATA = [
  {
    name: 'type',
    label: 'Type',
    start: null,
    target: null,
  },
  {
    name: 'estimatedDifficulty',
    label: 'Estimated Difficulty',
    start: null,
    target: null,
  },
  {
    name: 'dayOfTheWeek',
    label: 'Day of the Week',
    start: null,
    target: null,
  },
  {
    name: 'fastPath',
    label: 'Fast Path',
    start: null,
    target: null,
  },
  {
    name: 'uniqueFastPaths',
    label: '# Unique Fast Paths',
    start: null,
    target: null,
  },
  {
    name: 'totalFastPaths',
    label: '# Total Fast Paths',
    start: null,
    target: null,
  },
  {
    name: 'gradeLevel',
    label: 'Grade Level',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'wordRank',
    label: 'Word Rank',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'baconSet',
    label: 'Bacon-Set',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'globalAverage',
    label: 'Global Average',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonyms',
    label: '# Synonyms',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'meanings',
    label: '# Meanings',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'diffTopTwoPos',
    label: 'Diff Top 2 PoS',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'topFivePos',
    label: '# Top 5 PoS',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsVerb',
    label: '# Synonyms Verb',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsNoun',
    label: '# Synonyms Noun',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsAdjective',
    label: '# Synonyms Adjective',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsAdverb',
    label: '# Synonyms Adverb',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsOtherPos',
    label: '# Synonyms Other PoS',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsFirstMeaning',
    label: '# Synonyms in 1st Meaning',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsSecondMeaning',
    label: '# Synonyms in 2nd Meaning',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsThirdMeaning',
    label: '# Synonyms in 3rd Meaning',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsFourthMeaning',
    label: '# Synonyms in 4th Meaning',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsFifthMeaning',
    label: '# Synonyms in 5th Meaning',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'synonymsAllOtherMeanings',
    label: '# Synonyms All Other Meanings',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'averageGradeLevelOfSynonym',
    label: 'Average Grade Level of Synonym',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'averageGradeLevelOfFastPath',
    label: 'Average Grade Level of Fast Path',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'verbsInFastPaths',
    label: 'Verbs in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'nounsInFastPaths',
    label: 'Nouns in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'adjectivesInFastPaths',
    label: 'Adjectives in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'adverbsInFastPaths',
    label: 'Adverbs in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'otherPosInFastPaths',
    label: 'Other PoS in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'posSwitchInFastPaths',
    label: '# PoS Switch in Fast Paths',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'appearedInFastPath',
    label: 'Appeared in Fast Path',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'appearedAsFirstSynonym',
    label: 'Appeared as 1st Synonym',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'appearedAsTarget',
    label: 'Appeared as Target',
    start: 'Table cell',
    target: 'Table cell',
  },
  {
    name: 'preNotes',
    label: 'Pre-Notes',
    start: 'Table cell',
    target: 'Table cell',
  },
];

const STEPS_LIST = [
  { key: 'fast', label: 'Fast Path' },
  { key: 'one', label: '1' },
  { key: 'two', label: '2' },
  { key: 'three_to_five', label: '3-5' },
  { key: 'six_to_nine', label: '6-9' },
  { key: 'ten_plus', label: '10+' },
];

const HINT_EMOJI = {
  warmer: '🔴',
  hot: '🔴',
  keep_going: '⚪',
  colder: '🔵',
  got_it: '',
};

const POST_SECTION_INTRO = {
  yourPath: ['Nice win! Here\'s more about', 'how you played'],
  fastPath: ['Now let\'s take a look at', 'what could have been...'],
  SynonymSum: ['Next up, some fun ways', 'to visualize your progress'],
  mostCommon: ['Here are some paths from', 'around the community'],
  pickComp: ['Next up, some fun ways', 'to visualize your progress'],
  storyTime: ['This masterpiece', 'was hard-earned'],
};

const COMPLETE_STATUS = {
  NOT_STARTED: 'not_started',
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
};

export {
  MID_LIST,
  FAST_LIST,
  WORD_INFORMATION_LIST,
  YOUR_PATH_LIST,
  CHOICE_INFO,
  SINGLES_TALBE_DATA,
  LINEUP_TALBE_DATA,
  PAIRS_DATA,
  STEPS_LIST,
  HINT_EMOJI,
  POST_SECTION_INTRO,
  COMPLETE_STATUS
};
